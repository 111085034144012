import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid px-0" }
const _hoisted_2 = { class: "row gx-0 row-cols-2" }
const _hoisted_3 = { class: "card text-center text-bg-dark" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogPlaceholder = _resolveComponent("CatalogPlaceholder")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Trending", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_CatalogPlaceholder, { key: 0 }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.trending, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-sm-2",
              key: item
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: '/watch-' + item.media_type + '/' + item.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: 'https://image.tmdb.org/t/p/w342' + item.poster_path,
                      class: "card-img-top poster",
                      alt: "Poster Image"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h5", _hoisted_6, _toDisplayString(item.title) + _toDisplayString(item.name), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_router_link, {
                    class: "btn btn-primary",
                    to: '/watch-' + item.media_type + '/' + item.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-play" }),
                      _cache[0] || (_cache[0] = _createTextVNode(" Watch Now"))
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128))
    ])
  ]))
}