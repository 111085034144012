<template>
    <div class="container-fluid px-0">
        <h2>Top Rated Movies</h2>
        <div class="row gx-0 row-cols-2">
          <CatalogPlaceholder v-if="loading" />
            <div v-else class="col-sm-2" v-for="item in topMovies" :key="item">
                <div class="card text-center text-bg-dark">
                    <router-link :to="'/watch-movie/' + item.id">
                        <img :src="'https://image.tmdb.org/t/p/w342/' + item.poster_path"
                        class="card-img-top poster" :alt="item.title">
                    </router-link>
                    <div class="card-body">
                        <h5 class="card-title">{{item.title}}</h5>
                    </div>
                    <div class="card-footer">
                      <router-link class="btn btn-primary" :to="'/watch-movie/' + item.id"><font-awesome-icon icon="fa-solid fa-play" /> Watch Now</router-link>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getTopMovies } from '@/services/IMDBService'
import CatalogPlaceholder from '@/components/CatalogPlaceholder.vue'

export default defineComponent({
  name: 'TopMoviesCatalog',
  components: {
    CatalogPlaceholder
  },
  data () {
    return {
      topMovies: [],
      loading: true
    }
  },
  created () {
    this.getTopMovies()
  },
  methods: {
    getTopMovies () {
      getTopMovies().then(response => {
        this.topMovies = response.results
        this.loading = false
      })
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
