import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (n) => {
    return _createElementVNode("div", {
      class: "col-sm-2",
      key: n
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "card text-center" }, [
        _createElementVNode("svg", {
          class: "bd-placeholder-img card-img-top poster",
          width: "100%",
          height: "180",
          xmlns: "http://www.w3.org/2000/svg",
          role: "img",
          "aria-label": "Placeholder",
          preserveAspectRatio: "xMidYMid slice",
          focusable: "false"
        }, [
          _createElementVNode("title", null, "Placeholder"),
          _createElementVNode("rect", {
            width: "100%",
            height: "100%",
            fill: "#868e96"
          })
        ]),
        _createElementVNode("div", { class: "card-body" }, [
          _createElementVNode("h5", { class: "card-title placeholder-glow" }, [
            _createElementVNode("span", { class: "placeholder w-75" })
          ])
        ]),
        _createElementVNode("div", { class: "card-footer placeholder-glow" }, [
          _createElementVNode("a", { class: "btn btn-primary disabled placeholder w-75" })
        ])
      ], -1)
    ]))
  }), 64))
}