<template>
  <div class="container-fluid bg-dark text-light">
    <footer class="text-center container pt-5 pb-5">
      <p>{{ siteName }} is a Free Movies and TV Show/Series streaming site with zero ads. We let you watch movies or shows online without having to register or paying, with over 100000 movies and TV-Series. You can stream online or download to watch later!</p>
      <a href="https://movielairlinks.pages.dev/" target="_blank">All Movie Lair Websites</a>
      <ul>
        <li>
          <a href="https://movielair.cc/movies" target="_blank">Free Movies</a>
        </li>
        <li>
          <a href="https://movielair.cc/shows" target="_blank">Free TV Shows</a>
        </li>
        <li>
          <a href="https://movielair.cc/shows/16/1" target="_blank">Free Anime</a>
        </li>
      </ul>
      <p>Copyright &copy; {{ year }} {{ siteName }}</p>
      <p class="lead"><em>Disclaimer: This site does not store any files on its server. All contents are provided by non-affiliated third parties.</em></p>
      <ul>
        <li>Movie Lair</li>
        <li>movie for free</li>
        <li>Free movies online</li>
        <li>123 movies</li>
        <li>Watch series free</li>
        <li>fmovies</li>
        <li>Anime free online</li>
      </ul>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'FooterView',
  props: {
    siteName: String
  },
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style: none;
}

ul li {
  display: inline;
  padding-right: 1em;
}
</style>
