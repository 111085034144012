<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} - MovieLair` : `MovieLair` }}</template>
  </metainfo>
  <NavBar :siteName="siteName"/>
  <div class="container text-center" v-if="updateExists">
    <div class="alert alert-info fade show" role="alert">
      <strong>Update Available!</strong> Please refresh to update Movie Lair.
      <button type="button" @click="refreshApp" class="btn btn-outline-primary" aria-label="Update">Update</button>
    </div>
  </div>
  <router-view class='mainContent'/>
  <Footer :siteName="siteName"/>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
// import { getNotifications } from './services/db'
import { useHead } from '@unhead/vue'
import { time } from 'vue-gtag'

export default {
  components: {
    NavBar,
    Footer
  },
  setup () {
    useHead({
      title: 'Watch Movies, TV Shows, & Anime Free Online',
      description: 'Movie Lair is a Free Movies and TV Show/Series streaming site with zero ads. We let you watch movies or shows online without having to register or paying, with over 100000 movies and TV-Series. You can stream online or download to watch later!',
      meta: [
        {
          name: 'description',
          content: 'Movie Lair is a Free Movies and TV Show/Series streaming site with zero ads. We let you watch movies or shows online without having to register or paying, with over 100000 movies and TV-Series. You can stream online or download to watch later!'
        },
        { property: 'og:title', content: 'Movie Lair - Free Movies and TV Shows' },
        { property: 'twitter:title', content: 'Movie Lair - Free Movies and TV Shows' },
        { property: 'og:image', content: 'https://storage.googleapis.com/afs-prod/media/e53811360eed4b8ba26b5f635d703a7c/3000.jpeg' },
        { property: 'twitter:image', content: 'https://storage.googleapis.com/afs-prod/media/e53811360eed4b8ba26b5f635d703a7c/3000.jpeg' }
      ]
    })
  },
  data () {
    return {
      siteName: 'MovieLair',
      registration: null,
      updateExists: false,
      refreshing: false,
      session: {}
    }
  },
  created () {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // check if browser supports service worker to fix typeerror
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    } else {
      console.error('Service workers are not supported. Please use a modern browser or manually update the site by clearing cache.')
    }
  },
  mounted () {
    this.timeTrack()
  },
  methods: {
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    timeTrack () {
      // Feature detects Navigation Timing API support.
      if (window.performance) {
        // Gets the number of milliseconds since page load
        // (and rounds the result since the value must be an integer).
        const timeSincePageLoad = Math.round(performance.now())

        // send timing event
        time({
          name: 'page_load',
          value: timeSincePageLoad,
          event_category: 'Overall Application Performance'
        })
      }
    }
  }
}
</script>

<style lang="scss">
// Configuration
@import "~bootstrap/scss/functions";

// Default variable overrides

$primary: #32a7e2;
$secondary: #6c757d;
$success: #22b07d;
$info: #0dcaf0;
$warning: #ff7551;
$danger: #ea5f5f;
$light: #f8f9fa;
$dark: #121527;

// Halloween theme
/**
$primary: #FF7900;
$secondary: #6c757d;
$success: #04a777;
$info: #0dcaf0;
$warning: #FF7900;
$danger: #FF6D00;
$light: #f8f9fa;
$dark: #240046;
 */
// more Configuration
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

body {
  background-color: $dark;
  color: $light;
}

$enable-rounded: true;

.poster {
  height: 276px;
}

.card:hover {
  transform: scale(1.1);
  z-index: 99 !important;
}

.card {
  border-width: 0px;
  min-height: 426px;
  background-color: $dark;
}

.card-footer {
  border-top: 0;
  padding-top:0;
  background-color: inherit;
}

.card-body {
  padding-bottom:0;
}

.card-img-top {
  border-width: 0px;
}

.mainContent {
  min-height: 65vh;
}

.btn {
  border-radius: 2rem;
}
</style>
