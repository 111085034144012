import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid bg-dark text-light" }
const _hoisted_2 = { class: "text-center container pt-5 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("footer", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.siteName) + " is a Free Movies and TV Show/Series streaming site with zero ads. We let you watch movies or shows online without having to register or paying, with over 100000 movies and TV-Series. You can stream online or download to watch later!", 1),
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "https://movielairlinks.pages.dev/",
        target: "_blank"
      }, "All Movie Lair Websites", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "https://movielair.cc/movies",
            target: "_blank"
          }, "Free Movies")
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "https://movielair.cc/shows",
            target: "_blank"
          }, "Free TV Shows")
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "https://movielair.cc/shows/16/1",
            target: "_blank"
          }, "Free Anime")
        ])
      ], -1)),
      _createElementVNode("p", null, "Copyright © " + _toDisplayString(_ctx.year) + " " + _toDisplayString(_ctx.siteName), 1),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead" }, [
        _createElementVNode("em", null, "Disclaimer: This site does not store any files on its server. All contents are provided by non-affiliated third parties.")
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("ul", null, [
        _createElementVNode("li", null, "Movie Lair"),
        _createElementVNode("li", null, "movie for free"),
        _createElementVNode("li", null, "Free movies online"),
        _createElementVNode("li", null, "123 movies"),
        _createElementVNode("li", null, "Watch series free"),
        _createElementVNode("li", null, "fmovies"),
        _createElementVNode("li", null, "Anime free online")
      ], -1))
    ])
  ]))
}