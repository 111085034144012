const url = 'https://api.themoviedb.org/3'
const key = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIwOTI4NDZhMmJjOWFiYzg0NjkwMzc0ZjIxYjViOWM5OSIsInN1YiI6IjYyZWU4ZmQ4ODEzY2I2MDA3YjMzNGFmMCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.3h8uCmUwysjYBuSeNxdxsCDmmPaVTkYnuxe03tsR1Eo'
/* eslint @typescript-eslint/no-var-requires: "off" */
const axios = require('axios')

const instance = axios.create({
  headers: {
    common: {
      Authorization: 'Bearer ' + key,
      'Content-Type': 'application/json;charset=utf-8'
    }
  }
})

export async function getGuestSession () {
  const response = await instance.get(url + '/authentication/guest_session/new', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getMovieDetails (id) {
  const response = await instance.get(url + '/movie/' + id + '?append_to_response=keywords', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getShowDetails (id) {
  const response = await instance.get(url + '/tv/' + id + '?append_to_response=keywords', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getPersonDetails (id) {
  const response = await instance.get(url + '/person/' + id, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function searchTitle (query, page) {
  const response = await instance.get(url + '/search/multi?query=' + query + '&page=' + page + '&include_adult=false', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function searchMovie (query, page) {
  const response = await instance.get(url + '/search/movie?query=' + query + '&page=' + page + '&include_adult=false', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function searchShow (query, page) {
  const response = await instance.get(url + '/search/tv?query=' + query + '&page=' + page + '&include_adult=false', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function searchPerson (query, page) {
  const response = await instance.get(url + '/search/person?query=' + query + '&page=' + page + '&include_adult=false', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getTrending () {
  const response = await instance.get(url + '/trending/all/week', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getTrendingToday () {
  const response = await instance.get(url + '/trending/all/day', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getTopMovies () {
  const response = await instance.get(url + '/movie/top_rated', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getTopShows () {
  const response = await instance.get(url + '/tv/top_rated', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getSimilarMovies (id) {
  const response = await instance.get(url + '/movie/' + id + '/similar', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getSimilarShows (id) {
  const response = await instance.get(url + '/tv/' + id + '/similar', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getEpisodes (id, season) {
  const response = await instance.get(url + '/tv/' + id + '/season/' + season, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getMovieGenres () {
  const response = await instance.get(url + '/genre/movie/list', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getMoviesByGenre (genre, page) {
  const response = await instance.get(url + '/discover/movie?with_genres=' + genre + '&page=' + page, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getPersonMovies (genre, page) {
  const response = await instance.get(url + '/discover/movie?with_people=' + genre + '&page=' + page, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getPersonShows (genre, page) {
  const response = await instance.get(url + '/discover/tv?with_people=' + genre + '&page=' + page, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getShowGenres () {
  const response = await instance.get(url + '/genre/tv/list', {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}

export async function getShowsByGenre (genre, page) {
  const response = await instance.get(url + '/discover/tv?with_genres=' + genre + '&page=' + page, {
    validateStatus: function (status) {
      return status < 500
    }
  })
  return await response.data
}
