import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home container-xxl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeBanner = _resolveComponent("HomeBanner")!
  const _component_TrendingCatalog = _resolveComponent("TrendingCatalog")!
  const _component_TopMoviesCatalog = _resolveComponent("TopMoviesCatalog")!
  const _component_TopShowsCatalog = _resolveComponent("TopShowsCatalog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeBanner, { msg: _ctx.siteName }, null, 8, ["msg"]),
    _createVNode(_component_TrendingCatalog),
    _createVNode(_component_TopMoviesCatalog),
    _createVNode(_component_TopShowsCatalog)
  ]))
}