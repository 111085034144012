<template>
    <div class="col-sm-2" v-for="n in 20" :key="n">
        <div class="card text-center">
            <svg class="bd-placeholder-img card-img-top poster" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg>
            <div class="card-body">
                <h5 class="card-title placeholder-glow">
                    <span class="placeholder w-75"></span>
                </h5>
            </div>
            <div class="card-footer placeholder-glow">
                <a class="btn btn-primary disabled placeholder w-75" ></a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CatalogPlaceholder',
  data () {
    return {
      items: 6
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
