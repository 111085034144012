import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import VueGtag from 'vue-gtag'
import 'bootstrap'

import { createHead } from '@unhead/vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faFilm, faTv, faHouse, faPlay, faStarHalfStroke, faCalendarDay, faClock, faGhost, faBookmark } from '@fortawesome/free-solid-svg-icons'

import { faDiscord, faBitcoin, faEthereum, faMonero } from '@fortawesome/free-brands-svg-icons'
import './registerServiceWorker'

/* add icons to the library */
library.add(faFilm, faTv, faHouse, faPlay, faStarHalfStroke, faCalendarDay, faClock, faDiscord, faGhost, faBitcoin, faEthereum, faMonero, faBookmark)

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router)

const head = createHead()
app.use(head)

Sentry.init({
  app,
  dsn: 'https://3547f91abdb0434b9eacad26b2b375f4@o1350828.ingest.sentry.io/6630757',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
  // replay sample rate
  replaysSessionSampleRate: 0.1,
  // replay sample rate for errors
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/movielair\.cc/, /^https:\/\/crime\.cx/]
})

/**
// error handling for router
router.onError((error) => {
  Sentry.captureException(error)
  // if loading chunk error then reload page
  if (/Loading chunk \d* failed./i.test(error.message)) {
    // check if online
    if (navigator.onLine) {
      // reload page
      window.location.reload()
    }
  }
})
 */

app.use(VueGtag, {
  appName: 'Movie Lair',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-BNL4SZSSSL' }
}, router)

// enables performance monitoring in development enviroment
// works with vue extension and browser dev tools
app.config.performance = true

app.mount('#app')
