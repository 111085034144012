<template>
  <div class="home container-xxl">
    <HomeBanner :msg="siteName"/>
    <TrendingCatalog/>
    <TopMoviesCatalog/>
    <TopShowsCatalog/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HomeBanner from '@/components/HomeBanner.vue' // @ is an alias to /src
import TrendingCatalog from '@/components/TrendingCatalog.vue'
import TopMoviesCatalog from '@/components/TopMoviesCatalog.vue'
import TopShowsCatalog from '@/components/TopShowsCatalog.vue'
import { time } from 'vue-gtag'

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeBanner,
    TrendingCatalog,
    TopMoviesCatalog,
    TopShowsCatalog
  },
  data () {
    return {
      siteName: 'Movie Lair'
    }
  },
  mounted () {
    this.timeTrack()
  },
  methods: {
    timeTrack () {
      // Feature detects Navigation Timing API support.
      if (window.performance) {
        // Gets the number of milliseconds since page load
        // (and rounds the result since the value must be an integer).
        const timeSincePageLoad = Math.round(performance.now())

        // send timing event
        time({
          name: 'home_page_load',
          value: timeSincePageLoad,
          event_category: 'Overall Application Performance'
        })
      }
    }
  }
})
</script>
