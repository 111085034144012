<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <router-link class="navbar-brand" to="/">
      <!-- <img src="@/assets/jack-o-lantern.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top"> -->
      {{ siteName }}
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link" to="/"><font-awesome-icon icon="fa-solid fa-house" /> Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/movies"><font-awesome-icon icon="fa-solid fa-film" /> Movies</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/shows"><font-awesome-icon icon="fa-solid fa-tv" /> TV Shows</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" to="/halloween"><font-awesome-icon icon="fa-solid fa-ghost" /> Halloween</router-link>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="https://discord.gg/ZvRTYqTWtZ" target="_blank"><font-awesome-icon icon="fa-brands fa-discord" /> Discord</a>
        </li>
      </ul>
      <form class="d-flex" role="search" rel="search" name="navSearch" @submit.stop.prevent="submitClick">
        <input v-on:keyup.enter="submitClick" class="form-control search-input" type="search" placeholder="Search" aria-label="Search" form="navSearch" v-model="query" autocomplete="on" minlength="1" required>
        <button class="btn btn-success search-button" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBar',
  data () {
    return {
      query: ''
    }
  },
  props: {
    siteName: String
  },
  methods: {
    submitClick () {
      window.location.href = '/search/' + encodeURIComponent(this.query) + '/1'
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.search-input {
  border-radius: 15px 0px 0px  15px;
}

.search-button {
  border-radius: 0px 15px 15px 0px;
}

</style>
